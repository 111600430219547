import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators,AbstractControl } from '@angular/forms';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import Swal from "sweetalert2";
import moment from 'moment';
import { getSwal } from '../../../utils/swalObject';
import { CpfCnpjPipe } from '../../../pipes/cpf-cnpj.pipe';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/service/session/session.service';
import { InfoPerfilService } from 'src/app/service/info-perfil/info-perfil.service';
//import { CepService } from '../../../service/cep/cep.service';

@Component({
  selector: 'app-perfil',
  templateUrl: 'perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilUserComponent implements OnInit {
  EditUser: FormGroup;
  user: object = null;
  loadDataCriacao: any;

  constructor(
    private perfilDataService: PerfilDataService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private docPipe: CpfCnpjPipe,
    private sessionService: SessionService,
    private infoPerfilService: InfoPerfilService
    //private cepService: CepService,
  ) {}

  ngOnInit() {
    this.createForm();
    this.perfilDataService.getDateUSer().subscribe(data => {
      this.updateForm(data.resultado);
    });
  }

  private customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } {
    const emailError = Validators.email(control);
    if (control.value && emailError) {
      return { email: {} };
    }

    return null;
  }


  createForm() {
    this.EditUser = this.fb.group({
      tipoIdentificacao: [{ value: '', disabled: true }, Validators.required],
      cpfCnpj: [{ value: '', disabled: false }, Validators.required],
      nomeRazaoSocial: [{ value: '', disabled: false }, Validators.required],
      dataCriacao: [{ value: '', disabled: true }, Validators.required],
      situacao: [{ value: '', disabled: false }, Validators.required],
      email: [{ value: '', disabled: false }, Validators.required],
      ddd:  [{ value: '', disabled: false }],
      celular:  [{ value: '', disabled: false }],
      detalhe: this.fb.group({
        //cep:  [{ value: '', disabled: false }],
        //complemento:  [{ value: '', disabled: false }],
        //logradouro:  [{ value: '', disabled: false }],
        //numero:  [{ value: '', disabled: false }],
        inscricaoMunicipal:  [{ value: '', disabled: false }],
        documentoLink:  [{ value: '', disabled: false }],
        situacaoUsuario: [{value: '', disabled: false}, Validators.required],
      }),
    });
  }


  /*getAddress(cep: any) {
    if (cep && cep.length != 0) {
      
      cep = cep.replace(/\s/g, '');
      cep = cep.replace('-', '');
      let response;
      this.cepService
        .getAddressByZipCode(cep)
        .subscribe(
          address => {
            
            if(address.erro){
              this.handleErrors(null);
            }
            
            this.handleCepResponse(address);
           },
          error => {this.handleErrors(error);}
        );
    }
  }

  handleCepResponse(address) {
    

    this.EditUser.controls['detalhe'].patchValue({
        cepContribuinte: address.cep,
        estadoContribuinte: address.uf,
        cidadeContribuinte: address.localidade,
        logradouroContribuinte: address.logradouro,
        bairroContribuinte: address.bairro
    });

    this.EditUser.controls['estadoContribuinte'].disable();
    this.EditUser.controls['cidadeContribuinte'].disable();
    this.EditUser.controls['logradouroContribuinte'].disable();
    this.EditUser.controls['bairroContribuinte'].disable();
    
  }

  handleErrors(error) {
    Swal.fire(
      getSwal(
        'warning',
        'C.E.P. Inválido',
        'Por favor, verifique os dados informados e tente novamente. Caso o erro persista, consulte os Correios para confirmar os dados.',
        'ok',
        null,
      ),
    ).then(res => {
      console.log('buscaCep', res);
    });
  }*/

  updateForm(data) {
    for (let key in data) {
      if (data[key] && this.EditUser.controls[key]) {
        if (key === 'cpfCnpj') {
          this.EditUser.controls[key].patchValue(
            this.docPipe.transform(data[key]),
          );
        } else {
          this.EditUser.controls[key].patchValue(data[key]);
        }
      }
    }
    this.user = data;
    this.loadDataCriacao = data['dataCriacao'];
    const dateString = moment(new Date(data['dataCriacao'])).format(
      'DD/MM/YYYY - HH:mm:ss',
    );
    this.EditUser.controls['dataCriacao'].patchValue(dateString);
  }

onSubmit() {
  const form = this.EditUser.getRawValue();
  for (let key in form) {
    if (key !== 'detalhe' && this.user[key] !== undefined) {
      this.user[key] = form[key];
    } else if (key === 'detalhe') {
      let detalhe = form['detalhe'];
      for (let key in detalhe) {
        if (typeof detalhe[key] === 'undefined' || detalhe[key] === '') {
          detalhe[key] = null;
        }
      }
      this.user['detalhe'] = detalhe;
    }
  }
  this.user['dataCriacao'] = this.loadDataCriacao;

  this.perfilDataService.editarUsuario(this.user).subscribe(
    resposta => {
      Swal.fire(
        getSwal(
          'success',
          'Alterado',
          'Usuário Atualizado com Sucesso',
          'ok',
          null,
        ),
      ).then(res => {
        const usuarioSession = JSON.parse(localStorage.getItem('session') || '{}');    
        usuarioSession.usuario.nomeRazaoSocial = form['nomeRazaoSocial'];
        this.sessionService.setSession(usuarioSession);
        localStorage.setItem('session', JSON.stringify(usuarioSession));
        this.infoPerfilService.setUserState(usuarioSession);
        this.router.navigate(['/home']);
      });
    },
    error => {
      console.log('error', error);
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          error.error.excecao.recomendacao,
          'ok',
          null,
        )
      );
    },
  );
}


  desistir() {
    this.router.navigate(['/home']);
  }

  ajuda() {
    Swal.fire({
      html:'Se estiver em Sim, o usuário, assim como os comunicados trocados por este, não irão ser contabilizados em relatórios e consultas.',
      confirmButtonText:'Fechar'
    })
  }
}