import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import {
  FormGroup,
  FormBuilder,
  Validators,
  MinLengthValidator,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardService } from '../../../../service/wizard/wizard.service';
import { CadastroService } from '../../../../service/cadastro/cadastro.service';
import { ConsultaUsuarioService } from '../../../../service/consulta-usuario/consultar.user.service';

import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../../utils/swalObject';
import { PatternsInterface } from 'src/app/patterns/patterns.interface';
import { CboService } from 'src/app/service/cbo/cbo.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dados-procurador',
  templateUrl: 'dados-procurador.component.html',
  styleUrls: ['./dados-procurador.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
    ]),
  ],
})
export class DadosProcuradorComponent implements OnInit {
  index = 2;
  procuradorForm: FormGroup;
  fisicaForm: FormGroup;

  loading: boolean = false;
  data = {};
  infoBasica = null;
  codigoCBO: any;
  pessoa: string = 'fisica';
  labels: any;
  private routeSubscription: any;

  cnpjMask = new PatternsInterface().cnpj;
  cpfMask = new PatternsInterface().cpf;
  phoneMask = new PatternsInterface().phone;
  celMask = new PatternsInterface().cel;

//   telefoneMask = function(telefone: any) {
//     if(telefone.length == 10){
//         return [/\d/, /\d/, /\d/, /\d/ , /\d/, '-', /\d/, /\d/, /\d/, /\d/];
//     }else{
//         return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
//     }
// }

  telefoneCelular:any;

  prefeitura:any;
  personalidadeJuridica:any;

  setorAtuacao:any;

  cpfCnpjProcurador:any;
  token: string;
  servicoSms: number;
  dadoCbo: any;
  idOcupacao: any;
  servicoEmail: number;
  estadoCivil: any;


  constructor(
    private wizardService: WizardService,
    private cadastroService: CadastroService,
    private fb: FormBuilder,
    private consultaUsuario: ConsultaUsuarioService,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private cboService: CboService
  ) {
    this.wizardService.updateIndex(this.index);
    this.pessoa = this.wizardService.userData.tipo;
    this.setLabels();
  }

  ngOnInit() {

    let obj = {
      grant_type: "password",
      apikey: "CP7049PivNaOKpQE1gLt7mSJdGdJ0O9adGLhozrbd+cV5HG4Khk+8mnqISweKrQ/ZqLMGQxJTP2Q97A19o/vXg==",
      tipoLogin: 5
    }
    this.cboService.getToken(obj).subscribe(
      res => {
        this.token = res['access_token'];
        localStorage.setItem('tokenCbo', this.token);
      }
    )

    setTimeout(() => {
      this.cboService.getCbo().subscribe(
        res => {
          this.dadoCbo = res['resultado'];
          this.dadoCbo.sort((a,b) => a.descricao.localeCompare(b.descricao));
          if(this.dadoCbo.length == 0 || this.dadoCbo == null){
            swal.fire(
              getSwal(
                'error',
                'Ops!',
                'Ambiente não configurado. Aguarde o Administrador da Plataforma finalizar a liberação do ambiente.',
                'ok',
                null
              )
            );
          }
        }, error => {
          swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ocorreu um erro ao carregar a lista de Profissões.',
              'ok',
              null
            )
          );
        }    
      )
    }, 1500);
    this.personalidadesJuridicasativos();
    this.setorAtuacaosativos();
    this.getEstadoCivil();

    if (localStorage.getItem('data')){
      this.data = JSON.parse(localStorage.getItem('data'));
      this.data['perfil'] = localStorage.getItem('perfil');
      this.data['tipo'] = localStorage.getItem('tipo');
      this.pessoa = localStorage.getItem('tipo');
      this.wizardService.userData = this.data;
      this.prefeitura = this.wizardService.userData.prefeituras[0];
      this.servicoSms = this.prefeitura.configuracao.servicoSms;
      this.servicoEmail = this.prefeitura.configuracao.servicoEmail
    }else{
      this.data = this.wizardService.userData;
      this.prefeitura = this.wizardService.userData.prefeituras[0];
      this.servicoSms = this.prefeitura.configuracao.servicoSms;
      this.servicoEmail = this.prefeitura.configuracao.servicoEmail
    }

    this.infoBasica = this.data['infoBasica'];

    if (this.wizardService.userData.infoBasica == {}) {
      this.criarFormulario();
    } else {
      this.edit();
    }

    this.routeSubscription = this.route.queryParams.subscribe((params) => {
      console.log("params", params)
      if (params["isPessoaJuridica"] == "true") {       
        setTimeout(() => {
          this.procuradorForm.controls["cpfCnpjProcurador"].disable();
          this.procuradorForm.controls["nomeRazaoSocialProcurador"].disable();
          this.procuradorForm.controls.cpfCnpjProcurador.setValue(params['cnpj']);
          this.procuradorForm.controls.nomeRazaoSocialProcurador.setValue(params.companyName);
          this.procuradorForm.controls["cpfCnpjProcurador"].updateValueAndValidity();
          this.procuradorForm.controls["nomeRazaoSocialProcurador"].updateValueAndValidity();
          this.consultarUsuario(params['cnpj']);
        }, 500);
      }else if(params["isPessoaFisica"] == "true"){
        setTimeout(() => {
          this.procuradorForm.controls["cpfCnpjProcurador"].disable();
          this.procuradorForm.controls["nomeRazaoSocialProcurador"].disable();
          this.procuradorForm.controls.cpfCnpjProcurador.setValue(params['cpf']);
          this.procuradorForm.controls.nomeRazaoSocialProcurador.setValue(params.responsavel);
          this.procuradorForm.controls["cpfCnpjProcurador"].updateValueAndValidity();
          this.procuradorForm.controls["nomeRazaoSocialProcurador"].updateValueAndValidity();
          this.consultarUsuario(params['cpf']);
        }, 500);
      }
    });
  }

  async edit() {
    let criaFormulario = await this.criarFormulario();
    this.updateForm();
  }


  criarFormulario() {

    if (this.prefeitura.configuracao.exigeCertificadoCnpj == 1) {

    this.procuradorForm = this.fb.group({


    comboPersonalidadeJuridicaProcurador:["",Validators.compose([Validators.required])],
    comboSetorAtuacaoProcurador:         ["",Validators.compose([Validators.required])],
    personalidadeJuridicaProcurador:{codigo:''},
    setorAtuacaoProcurador:{codigo:''},


      cpfCnpjProcurador: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(18),
        ]),
      ],
      nomeRazaoSocialProcurador: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(160)]),
      ],
      inscricao: [
        ''
      ],
      telefoneCelular: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(11), Validators.minLength(10)]),
      ],
      email: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ],
      orgaoEmissorRG: [
        '',
      ],
      numeroRG: [
        '',
      ],
      codigoEstadoCivil: [
        ''
      ],
      codigoCBO: [
        '',
      ]
    });
  }else{
    this.procuradorForm = this.fb.group({

      comboPersonalidadeJuridicaProcurador:["",Validators.compose([Validators.required])],
      comboSetorAtuacaoProcurador:         ["",Validators.compose([Validators.required])],


        cpfCnpjProcurador: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(14),
            Validators.maxLength(18),
          ]),
        ],
        nomeRazaoSocialProcurador: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(160)]),
        ],
        inscricao: [
          ''
        ],
        telefoneCelular: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(11),Validators.minLength(10)]),
        ],
        email: [
          '',
          Validators.compose([Validators.required, Validators.maxLength(60)])
        ],
        orgaoEmissorRG: [
          '',
        ],
        numeroRG: [
          '',
        ],
        codigoEstadoCivil: [
          ''
        ],
        codigoCBO: [
          '',
        ]
      });
  }

    if (this.prefeitura.configuracao.exigeCertificadoCnpj === 0) {
      this.procuradorForm.controls["comboPersonalidadeJuridicaProcurador"].setValidators([]);
      this.procuradorForm.controls["comboSetorAtuacaoProcurador"].setValidators([]);
    }
    
    if(this.pessoa == 'fisica') {
      this.procuradorForm.controls['comboPersonalidadeJuridicaProcurador'].setValue("0");
      this.procuradorForm.controls['comboSetorAtuacaoProcurador'].setValue("0");
      this.procuradorForm.controls['orgaoEmissorRG'].setValidators([
        Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4)])
      ]);
      this.procuradorForm.controls['numeroRG'].setValidators([
        Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])
      ]);
      this.procuradorForm.controls['codigoCBO'].setValidators([
        Validators.compose([Validators.required, Validators.maxLength(10)])
      ]);
      this.procuradorForm.controls['codigoEstadoCivil'].setValidators([
        Validators.compose([Validators.required])
      ]);
    }
  }

  updateForm() {
    let data = this.wizardService.userData.infoBasica;
    for (let key in data) {
      if (
        typeof key !== 'undefined' &&
        key !== 'telefoneCelular' &&
        key !== 'dddTelefoneCelular' &&
        key !== 'pessoaFisica' &&
        key !== "personalidadeJuridicaProcurador" &&
        key !== "compoPersonalidadeJuridicaProcurador" &&
        key !== "pessoa" &&
        key !== "setorAtuacaoProcurador" &&
        key !== "comboSetorAtuacaoProcurador"
      ) {
        this.procuradorForm.controls[key].patchValue(data[key]);
      } else if (key === 'telefoneCelular') {
        let telefoneCelular =
          data['dddTelefoneCelular'] && data['telefoneCelular']
            ? data['dddTelefoneCelular'].replace(/\D+/g, '').concat(data['telefoneCelular'])
            : '';
        this.procuradorForm.controls['celular'].patchValue(telefoneCelular);
      } else {

        this.procuradorForm.controls['comboPersonalidadeJuridicaProcurador'].setValue(data["comboPersonalidadeJuridicaProcurador"]);
        this.procuradorForm.controls['comboSetorAtuacaoProcurador'].setValue(data["comboSetorAtuacaoProcurador"]);
      }
    }
  }

  get f() { 
    return this.procuradorForm.controls;
  }

  consultarUsuario(id: string) {
    if (this.procuradorForm.controls.cpfCnpjProcurador.valid || this.procuradorForm.controls.cpfCnpjProcurador.disabled) {
      this.consultaUsuario.consultarUsuario(this.procuradorForm.controls.cpfCnpjProcurador.value).subscribe(
        res => {
          this.loading = false;
          let data = res.resultado;
          this.wizardService.userData['id'] = data.cpfCnpj;
          this.wizardService.userData['codigo'] = data.codigo;

          if (data.situacao === 0) {
            this.procuradorForm.patchValue({
              //cpfCnpjProcurador: data.cpfCnpj,
              nomeRazaoSocialProcurador: data.nomeRazaoSocial,
              telefoneCelular:
                data.ddd &&
                data.celular
                  ? data.ddd.replace(/\D+/g, '').concat(
                    data.celular,
                  )
                  : '',
              email: data.email,
              comboPersonalidadeJuridicaProcurador:
                  data.detalhe && data.detalhe.personalidadeJuridica
                    ? data.detalhe.personalidadeJuridica.codigo
                    : "",
              comboSetorAtuacaoProcurador:
                  data.detalhe && data.detalhe.setorAtuacao
                    ? data.detalhe.setorAtuacao.codigo
                    : "",      
              inscricao:
                  data.detalhe && data.detalhe.inscricaoMunicipal
                    ? data.detalhe.inscricaoMunicipal
                    : "",  
            });
            let obj = this.procuradorForm.value
            for (let key in obj) {
              if (key !== 'cpfCnpjProcurador' && this.procuradorForm.controls[key].value !== '') {
                this.procuradorForm.controls[key].disable();
              }
            }
          } else {
            swal.fire(
              getSwal(
                'success',
                'Usuário já cadastrado',
                'Você já está cadastrado em nosso sistema, defina sua senha para acessá-lo',
                'ok',
                null,
              ),
            ).then(res => {
              this.router.navigate(['/recuperar-senha']);
            });
          }
        },
        error => {
          if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
            swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            ).then(() => this.procuradorForm.get('cpfCnpjProcurador').setValue(null));
          }
          this.loading = false;
        },
      );
    }
  }

  proximo() {
    let data = this.procuradorForm.getRawValue();
    data['dddTelefoneCelular'] = data['telefoneCelular'].replace(/\D+/g, '').substring(0, 2);
    data['telefoneCelular'] = data['telefoneCelular'].replace(/\D+/g, '').substr(2, 10);
    data['pessoa'] = this.pessoa;

    if(data['pessoa'] == 'fisica'){
      for(let i = 0; i < this.dadoCbo.length; i++){
        if(this.procuradorForm.controls.codigoCBO.value == this.dadoCbo[i].idOcupacao){
          data['descricaoCbo'] = this.dadoCbo[i].descricao;
      } 
      }

      for(let i = 0; i < this.estadoCivil.length; i++){
        if(this.procuradorForm.controls.codigoEstadoCivil.value == this.estadoCivil[i].codigo){
          data['dscEstadoCivil'] = this.estadoCivil[i].dscEstadoCivil;
        }
      }
    }
    
    
    this.wizardService.userData.infoBasica = data;

    if(this.prefeitura.configuracao.exigeCertificadoCnpj==1){
      data.personalidadeJuridicaProcurador.codigo = data.comboPersonalidadeJuridicaProcurador;
      data.setorAtuacaoProcurador['codigo'] = data.comboSetorAtuacaoProcurador;
    }

    this.wizardService.userData.infoBasica = data;
    if (this.pessoa === 'fisica') {
      if(localStorage.getItem('certificado')){
        let certificado = localStorage.getItem('certificado');
        let obj = JSON.parse(certificado);
        console.log('certificado: ' +  JSON.stringify(obj));
        data["nonce"] = obj.resultado.nonce;
      }

      this.wizardService.userData.infoBasica['pessoaFisica'] = true;
      this.wizardService.userData.infoBasica = data;
      localStorage.setItem("data",JSON.stringify(this.wizardService.userData));
      this.router.navigate(['cadastro/procurador/resumo']);

    } else if(this.pessoa === 'juridica' && localStorage.getItem('certificado')) {
      let certificado = localStorage.getItem('certificado');
      let obj = JSON.parse(certificado);
      console.log('certificado: ' +  JSON.stringify(obj));
      data["nonce"] = obj.resultado.nonce;

      this.wizardService.userData.infoBasica['pessoaFisica'] = false;
      this.wizardService.userData.infoBasica = data;
      this.router.navigate(['cadastro/procurador/representante']);
      console.log('passou');
      
    } else if (this.pessoa === 'juridica') {
      this.wizardService.userData.infoBasica['pessoaFisica'] = false;
      this.wizardService.userData.infoBasica = data;
      localStorage.setItem("data",JSON.stringify(this.wizardService.userData));
      this.router.navigate(['cadastro/procurador/representante']);
    }
  }

  desistir() {
    this.wizardService.resetData();
    this.router.navigate(['/index']);
  }

  voltar() {
    this.index = this.index - 1;
    this.wizardService.updateIndex(this.index);
    this.location.back();
  }

  personalidadesJuridicasativos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        var i = 0;
        res.resultado.forEach(item => {
          res.resultado[i]["selecionado"] = "false"
          if(this.wizardService.userData.infoBasica.personalidadeJuridicaProcurador){
            // console.log(this.wizardService.userData.infoBasica.personalidadeJuridicaProcurador.codigo+" = "+ res.resultado[i].codigo)
            if(this.wizardService.userData.infoBasica.personalidadeJuridicaProcurador.codigo == res.resultado[i].codigo){
            res.resultado[i]["selecionado"] = "true"
            }
          }

          i++;
        });
        this.personalidadeJuridica = res.resultado;
        // console.log('this.personalidadeJuridica sele', this.personalidadeJuridica)

      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setorAtuacaosativos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        // console.log('setor atuacao:', res.resultado);
        var i = 0;
        res.resultado.forEach(item => {
          res.resultado[i]["selecionado"] = "false"
          if(this.wizardService.userData.infoBasica.setorAtuacaoProcurador){
            // console.log(this.wizardService.userData.infoBasica.setorAtuacaoProcurador.codigo+" = "+ res.resultado[i].codigo)
            if(this.wizardService.userData.infoBasica.setorAtuacaoProcurador.codigo == res.resultado[i].codigo){
            res.resultado[i]["selecionado"] = "true"
            }
          }

          i++;
        });
        this.setorAtuacao = res.resultado;
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  getEstadoCivil(){
    this.cadastroService.getEstadoCivil().subscribe(
      res => {
        this.estadoCivil = res.resultado;
        if(this.estadoCivil.length == 0 || this.estadoCivil == null){
          swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ambiente não configurado. Aguarde o Administrador da Plataforma finalizar a liberação do ambiente.',
              'Ok',
              null
            )
          )
        }
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar o Estado Civil.',
            'ok',
            null
          )
        );
      }
    );
  }


  setLabels() {
    this.labels = {
      title: this.translate.instant('GLOBAIS.SEUSDADOS'),
      cnpj: this.translate.instant('GLOBAIS.CNPJ'),
      cpf: this.translate.instant('GLOBAIS.CPF'),
      inscricao: this.translate.instant('GLOBAIS.INSCRICAO'),
      nome: this.translate.instant('GLOBAIS.NOME'),
      razaoSocial: this.translate.instant('GLOBAIS.RAZAOSOCIAL'),
      telefone: this.translate.instant('GLOBAIS.TELEFONE'),
      comercial: this.translate.instant('GLOBAIS.COMERCIAL'),
      celular: this.translate.instant('GLOBAIS.CELULAR'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      voltar: this.translate.instant('GLOBAIS.VOLTAR'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      prosseguir: this.translate.instant('GLOBAIS.PROSSEGUIR'),
      numeroRG: this.translate.instant('GLOBAIS.NUMERORG'),
      orgaoEmissorRG: this.translate.instant('GLOBAIS.ORGAOEMISSORRG'),
      codigoEstadoCivil: this.translate.instant('GLOBAIS.CODIGOESTADOCIVIL'),
      codigoCBO: this.translate.instant('GLOBAIS.CODIGOCBO'),

    };
  }

  telefoneMask(telefoneCelular) {
    const isCelular = telefoneCelular == null ? true : telefoneCelular.length == 11 ? true : false;
    return isCelular ? '(00) 0000-00000' : '(00) 0000-00000';
  }

  verificaRg(){
    if(this.procuradorForm.controls['numeroRG'].value == null){  
      this.procuradorForm.controls['orgaoEmissorRG'].clearValidators();
      this.procuradorForm.controls['orgaoEmissorRG'].updateValueAndValidity();
    }
    else{
      this.procuradorForm.controls['orgaoEmissorRG'].setValidators([
        Validators.required, Validators.maxLength(4), Validators.minLength(4)
      ]);
    }
  }

  razaoSocialInvalid(){
    if(this.procuradorForm.controls["nomeRazaoSocialProcurador"].value.trim().length == 0) {
      this.procuradorForm.controls["nomeRazaoSocialProcurador"].setValue("");

    }
  }

  ajuda(valor) {
    let msg1=
    ` <ol>
    <li> A Carteira de Identidade é um documento oficial de identificação que contém nome, data de nascimento, filiação, impressão digital e fotografia. Tem validade em todo o território nacional e é expedida para Brasileiros Natos, Naturalizados e os Portugueses que possuam Igualdade de Direitos. A Carteira de Identidade não tem prazo de validade.
    </li>
  </ol>`;

  let msg2=
    ` <ol>
    <li> Estado civil é o termo jurídico que faz referência à situação de um cidadão em relação ao matrimônio. A legislação brasileira identifica cinco tipos diferentes de estado civil, são eles: solteiro, casado, separado, divorciado e viúvo.
    </li>
  </ol>`;

  let msg3=
    ` <ol>
    <li> A Classificação Brasileira de Ocupações (CBO) é um documento que retrata a realidade das profissões do mercado de trabalho brasileiro. Foi instituída com base legal na Portaria nº 397, de 10.10.2002.
    </li>
  </ol>`;
 

    switch(valor) {
      case 'numeroRG': {
        Swal.fire({
          html:msg1,
          confirmButtonText:'OK'
        })
         break;
      }
    }

    switch(valor) {
      case 'codigoEstadoCivil': {
        Swal.fire({
          html:msg2,
          confirmButtonText:'OK'
        })
         break;
      }
    }

    switch(valor) {
      case 'codigoCBO': {
        Swal.fire({
          html:msg3,
          confirmButtonText:'OK'
        })
         break;
      }
    }
  }
}
