import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoPerfilService {
  private userState = new BehaviorSubject<any>(null);

  setUserState(data: any) {
    this.userState.next(data);
  }

  getUserState() {
    return this.userState.asObservable();
  }
}
